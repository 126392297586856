import React, { useState, useEffect } from "react";
import { asciiToTrytes, trytesToAscii } from '@iota/converter';
import Mam from '@iota/mam';
import isEmpty from 'lodash/isEmpty';
import uniqBy from 'lodash/uniqBy';
import pick from 'lodash/pick';
import last from 'lodash/last';
import memoize from 'lodash/memoize';
import { P, H1, Button, Input, Form, BodyWrapper } from "../components";
import { useForm } from 'react-hook-form'
//import {publish} from '../components/Mam';

const mode = 'restricted'
const secretKey = 'SECRETBIG' // secret always upper case!
const provider = 'https://nodes.devnet.iota.org:443'
const mamExplorerLink = `https://mam-explorer.firebaseapp.com/?provider=${encodeURIComponent(provider)}&mode=${mode}&key=${secretKey.padEnd(81, '9')}&root=`

let mamState = Mam.init(provider)
const baseRoot = Mam.getRoot(mamState);
mamState = Mam.changeMode(mamState, mode, secretKey)

const fetchItem = async (root) => {
    
  
      await Mam.fetch(root, 'restricted', secretKey);
      const result = await Mam.fetch(root, mode, secretKey);
      result.messages.forEach(message => console.log('Fetched and parsed', JSON.parse(trytesToAscii(message)), '\n'))

      
  };

  const getUniqueStatuses = itemEvents =>
  uniqBy(itemEvents.map(event => pick(event, ['status', 'timestamp'])), 'status');

export default () => {
    const [root, setRoot] = useState('');
    // const [dCity, setdCity] = useState('');
    //  const [desCity, setdesCity] = useState('');
    //  const [packageId, setpackageId] = useState('');
    // const [phone, setPhone] = useState('');
    // const [email, setEmail] = useState('');

const submitValue = async () => {
        
     const mamData = fetchItem (root);
    
    

}

return(
    <>
    <hr/>
    <input type="text" placeholder="Root" onChange={e => setRoot(e.target.value)} />
    {/* <input type="text" placeholder="Departure City" onChange={e => setdCity(e.target.value)} />
    <input type="text" placeholder="Destination City" onChange={e => setdesCity(e.target.value)} />
     */}
    <button onClick={submitValue}>Submit</button>
    
        
     
    </>
    )
}