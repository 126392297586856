import React from "react";
import { H1, H2, P, Button, BodyWrapper } from "../components";
import { withRouter } from "react-router-dom";

const Lander = props => {
  const signIn = () => {
    props.history.push("/signin");
  };

  return (
    <BodyWrapper>
      <H1>Supply Chain with IOTA</H1>
      <H2>
      Distributed Ledger Technology provides new trust in supply chains
      </H2>
      
      <Button onClick={signIn}>Click here to Sign In</Button>
    </BodyWrapper>
  );
};

export default withRouter(Lander);
