import facebook from "./icons/flogo-HexRBG-Wht-72.svg";
import google from "./icons/icons8-google-48.png";
import github from "./icons/github.svg";

const icons = {
  google: google,
  facebook: facebook,
  github: github
};

export default icons;
