import React, { useState, useEffect, useContext } from "react";
import { asciiToTrytes, trytesToAscii } from '@iota/converter';
import Mam from '@iota/mam';
import firebase from "../firebase.js";
import { P, H1, Button, Input, Form, BodyWrapper } from "../components";
import "firebase/firestore";
import { UserContext } from "../contexts/userContext";
import { ToastContext } from "../contexts/toastContext";

const mode = 'restricted'
const secretKey = 'ANYSECRETKEY' // secret always upper case!
const provider = 'https://nodes.devnet.iota.org:443'
const mamExplorerLink = `https://mam-explorer.firebaseapp.com/?provider=${encodeURIComponent(provider)}&mode=${mode}&key=${secretKey.padEnd(81, '9')}&root=`
const db = firebase.firestore();

let mamState = Mam.init(provider);
const baseRoot = Mam.getRoot(mamState);
mamState = Mam.changeMode(mamState, mode, secretKey)


export default () => {
    const [currentRoot, setCurrentRoot] = useState('');
    const [dCity, setdCity] = useState('');
     const [desCity, setdesCity] = useState('');
     const [packageId, setpackageId] = useState('');
     const [pCon, setpCon] = useState('');
     const [curCity, setcurCity] = useState('');
     const { userState, userDispatch } = useContext(UserContext);
     const { sendMessage } = useContext(ToastContext);

     const publish = async data => {
        try{
        // Create MAM Payload - STRING OF TRYTES
        const trytes = asciiToTrytes(JSON.stringify(data))
        const message = Mam.create(mamState, trytes)
      
        // Save new mamState
        mamState = message.state
        console.log(mamState);
      
        // Attach the payload
        await Mam.attach(message.payload, message.address, 3, 9)
      
        return message.root
     }
      catch(error){
          console.log('Error',error);
            return null;
        }
    };
    
const submitValue = () => {
    
        
     db.collection('packages').doc(packageId)
    .get().then (
        doc => {
            
                const payload = {
                    'PackageID' : packageId,
                    'DepartureCity' : dCity,
                    'DestinationCity' : desCity,
                    'PackageContent' : pCon,
                    'CurrentCity' : curCity,
                    timestamp: (new Date()).toLocaleString()
                    
                }
                const root = publish (payload);
                setCurrentRoot(root);
                db.collection("packages").doc(packageId).set(
                    {
                    'DepartureCity' : dCity,
                    'DestinationCity' : desCity,
                     mamdata : {"Root" : baseRoot}
                    
                    }
                )
                sendMessage("Package created successfully!");
                
        }
    )
}
const updateMAM = () => {

    db.collection('packages').doc(packageId)
    .get().then (
        doc => {
            if (doc.exists)
            {
                             

                const payload = {
                    'PackageID' : packageId,
                    'DepartureCity' : dCity,
                    'DestinationCity' : desCity,
                    'PackageContent' : pCon,
                    'CurrentCity' : curCity,
                    timestamp: (new Date()).toLocaleString()
                
                }
            
                const root = publish (payload);
                setCurrentRoot(root);
                
                
                sendMessage("Package updated successfully!");
            } 
            else{
                sendMessage("Something went wrong! / package does not exist");
            }
        }
    )
}

        
return(
    <>
    <hr/>
    <H1>Create Package </H1>
    <Input type="text" placeholder="Package ID" onChange={e => setpackageId(e.target.value)} />
    <Input type="text" placeholder="Departure City" onChange={e => setdCity(e.target.value)} />
    <Input type="text" placeholder="Destination City" onChange={e => setdesCity(e.target.value)} />
    <Input type="text" placeholder="Package Content" onChange={e => setpCon(e.target.value)} />
    <Input type="text" placeholder="Current City" onChange={e => setcurCity(e.target.value)} />
    
    <Button onClick={submitValue}>Submit</Button>

    <br/><br/>
    <div className="link-wrapper">Base root: <a
          className="App-link"
          href={mamExplorerLink + baseRoot}
          target="_blank"
          rel="noopener noreferrer"
        > {baseRoot}</a></div>
     <hr/>
    <H1>Update Package </H1>
    <Input type="text" placeholder="Package ID" onChange={e => setpackageId(e.target.value)} />
    <Input type="text" placeholder="Departure City" onChange={e => setdCity(e.target.value)} />
    <Input type="text" placeholder="Destination City" onChange={e => setdesCity(e.target.value)} />
    <Input type="text" placeholder="Package Content" onChange={e => setpCon(e.target.value)} />
    <Input type="text" placeholder="Current City" onChange={e => setcurCity(e.target.value)} />
    
    <Button onClick={updateMAM}>Submit</Button>
    
    </>
    )
}