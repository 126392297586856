import React from "react";
import styled from "styled-components";
import { metrics } from "../themes";
import { H1, H2, P, BodyWrapper } from "../components";

const OL = styled.ol`
  font-size: ${metrics.smallText}px;
  line-height: 2;
  margin-left: ${metrics.baseUnit * 3}px;
  padding-left: 0;
  margin-bottom: ${metrics.baseUnit * 3}px;
`;

const UL = styled.ul`
  font-size: ${metrics.smallText}px;
  line-height: 2;
  margin-left: ${metrics.baseUnit * 3}px;
  padding-left: 0;
  margin-bottom: ${metrics.baseUnit * 3}px;
`;

const PrivacyPolicy = () => {
  return (
    <BodyWrapper>
      
    </BodyWrapper>
  );
};

export default PrivacyPolicy;
