import * as firebase from "firebase/app";
import "firebase/messaging";
import "firebase/auth";
import "firebase/functions";
const config = {
  apiKey: "AIzaSyCGSzfdnpzPrkLiTf3hkcwusWe6Mspfdko",
  authDomain: "sgp-6-b7447.firebaseapp.com",
  databaseURL: "https://sgp-6-b7447.firebaseio.com",
  projectId: "sgp-6-b7447",
  storageBucket: "sgp-6-b7447.appspot.com",
  messagingSenderId: "705376551896",
  appId: "1:705376551896:web:4e1b4dab9e806bd2ada8ad",
  measurementId: "G-4ZQYT4BBJS",
};
firebase.initializeApp(config);

if ("Notification" in window) {
  const messaging = firebase.messaging();
  messaging.usePublicVapidKey(process.env.REACT_APP_FIREBASE_MESSAGING_CERT);

  messaging.onMessage(payload => {
    console.log("Message received. ", payload);
    // push message to UI
  });

  messaging.onTokenRefresh(() => {
    const db = firebase.firestore();
    messaging
      .getToken()
      .then(refreshedToken => {
        db.collection("users")
          .doc(firebase.auth().currentUser.uid)
          .update({ pushTokenWeb: refreshedToken })
          .then(() => {
            console.log("Token updated.");
          })
          .catch(err => console.log(err));
      })
      .catch(err => {
        console.log("Unable to retrieve refreshed token ", err);
      });
  });
}

export default firebase;
