import React, { useState, useEffect, useContext } from "react";
import { asciiToTrytes, trytesToAscii } from '@iota/converter';
import Mam from '@iota/mam';
import firebase from "../firebase.js";
import { P, H1, Button, Input, Form, BodyWrapper } from "../components";
import "firebase/firestore";
import { UserContext } from "../contexts/userContext";
import { ToastContext } from "../contexts/toastContext";

const mode = 'restricted'
const secretKey = 'SECRETBIG' // secret always upper case!
const provider = 'https://nodes.devnet.iota.org:443'
const mamExplorerLink = `https://mam-explorer.firebaseapp.com/?provider=${encodeURIComponent(provider)}&mode=${mode}&key=${secretKey.padEnd(81, '9')}&root=`
const db = firebase.firestore();

let mamState = Mam.init(provider)
mamState = Mam.changeMode(mamState, mode, secretKey)



export default () => {
    const [currentRoot, setCurrentRoot] = useState('');
    const [dCity, setdCity] = useState('');
     const [desCity, setdesCity] = useState('');
     const [packageId, setpackageId] = useState('');
     const [pCon, setpCon] = useState('');
     const [curCity, setcurCity] = useState('');
     const { userState, userDispatch } = useContext(UserContext);
     const { sendMessage } = useContext(ToastContext);
 


const submitValue = () => {
    
        
    const docRef = db.collection('packages').doc(packageId)
    .get().then (
        doc => {
            if (doc.exists)
            {
                db.collection("packages")
                .doc(packageId)
                .get()
                .then(doc => {
                const root = doc.data();
                console.log(root.mamdata.Root);
                const newroot = root.mamdata.Root;
                setCurrentRoot(newroot);
                console.log(currentRoot); 
                
                });
                const payload = {
                    'PackageID' : packageId,
                    'DepartureCity' : dCity,
                    'DestinationCity' : desCity,
                    'PackageContent' : pCon,
                    'CurrentCity' : curCity,
                    timestamp: (new Date()).toLocaleString()
                    // 'Phone' : phone,
                    // 'Email' : email
                }
                //const root = publish (payload);
                
                db.collection("packages").doc(packageId).update(
                    {
                    'DepartureCity' : dCity,
                    'DestinationCity' : desCity,
                     
                    
                    }
                )
                sendMessage("Package updated successfully!");
            }
            else{
                sendMessage("Package does not exists!");
                
                }
        }
    )
     

};

const publish = async data => {
    try{
    // Create MAM Payload - STRING OF TRYTES
    const trytes = asciiToTrytes(JSON.stringify(data))
    const message = Mam.create(mamState, trytes)
  
    // Save new mamState
    mamState = message.state
    console.log(message.root);
    console.log(message.address);
    console.log(currentRoot);
  
    // Attach the payload
    await Mam.attach(message.payload, currentRoot, 3, 9)
  
    return message.root
 }
  catch(error){
      console.log('Error',error);
        return null;
    }
};
return(
    <>
    <hr/>
    <H1>Update Package </H1>
    <Input type="text" placeholder="Package ID" onChange={e => setpackageId(e.target.value)} />
    <Input type="text" placeholder="Departure City" onChange={e => setdCity(e.target.value)} />
    <Input type="text" placeholder="Destination City" onChange={e => setdesCity(e.target.value)} />
    <Input type="text" placeholder="Package Content" onChange={e => setpCon(e.target.value)} />
    <Input type="text" placeholder="Current City" onChange={e => setcurCity(e.target.value)} />
    
    <Button onClick={submitValue}>Submit</Button>
    <br/><br/>
    
        
     
    </>
    )
}